<mat-toolbar color="primary" class="header-toolbar" *ngIf="showNavBarMenu">
  <app-loading *ngIf="loading"></app-loading>
  <mat-toolbar-row style="height: 50px">
    <div class="col-lg-3 col-sm-2 col-10 left_menu d-flex align-items-center">
      <div class="menu col-1 col-sm-1">
        <button
          *ngIf="minimize"
          type="button"
          mat-icon-button
          (click)="fecharmenu()"
        >
          <mat-icon aria-label="Side nav toggle icon"> menu</mat-icon>
        </button>
      </div>
      <div class="impostograma impostocerto col-sm-7">
        <a
          class="menu"
          [routerLink]="['/dashboard']"
          style="margin: 0px"
          (click)="acessoAnalytics('Acesso Home Page')"
        >
          <img
            class="logo"
            [src]="invalidPath"
            onError="this.src='assets/logo-white.svg'"
            alt="logo"
          />
        </a>
      </div>
    </div>
    <div class="col-lg-9 col-sm-9 col-2 row">
      <div class="col-lg-5 col-sm-3 col-1 hidden-xs">
        <div style="margin-top: 12px"></div>
      </div>
      <div
        class="col-lg-7 col-sm-9 col-10 text-right"
        style="align-items: center; display: flex; justify-content: end;"
      >
        <div class="legend">
          <div
            *ngIf="!isImpostocerto"
            class="gb"
            [matMenuTriggerFor]="menuArmazenamentoIa"
            #menuGigasTrigger="matMenuTrigger"
            (click)="handleButtonAnalytics('Visualizar Armazenamento IA')"
          >
            <small class="f10"
              ><strong>Produtos Associados pela IA</strong></small
            >
            <mat-progress-bar
              mode="determinate"
              [value]="
                calcularPorcentagem(
                  ProdutosRevisados,
                  ProdutosRevisadosContratado
                )
              "
              [color]="
                atualizarCor(ProdutosRevisados, ProdutosRevisadosContratado)
              "
            >
            </mat-progress-bar>

            <small class="f10" style="float: right"
              >{{ ProdutosRevisados }}/{{ ProdutosRevisadosContratado }}</small
            >
          </div>

          <mat-menu #menuArmazenamentoIa="matMenu" xPosition="before">
            <div *ngIf="isSustentacao" class="billing-container">
              <div class="billing-item">
                <div class="title_notificacao text-center">
                  <h3 style="margin: 0">Produtos Corrigidos</h3>
                  <p>
                    <small>
                      {{ DataHoraUltimaAtualizacao }}
                    </small>
                  </p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 0.5rem">
                  <div class="row">
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 0.5rem 0 1rem"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-left: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>Este Mês</strong></small>
                        <small class="f10">{{ ProdutosRevisadosMes }}</small>
                      </div>
                    </div>
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 1rem 0 0.5rem"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-right: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>Pacote</strong></small>
                        <small class="f10">{{
                          ProdutosRevisadosContratado
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 0.5rem 0 1rem"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-left: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>Total Geral</strong></small>
                        <small class="f10">{{ ProdutosRevisados }}</small>
                      </div>
                    </div>
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 1rem 0 0.5rem"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-right: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>Saldo</strong></small>
                        <small class="f10">{{
                          ProdutosRevisadosContratado - ProdutosRevisados
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="billing-item">
                <div class="title_notificacao text-center">
                  <h3 style="margin: 0">Adicionais ao Pacote</h3>
                  <p>
                    <small>R$ 0,68 por produtos corrigido</small>
                  </p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 0.5rem">
                  <div>
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin: 0 0.4rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>Por IA</strong> </small>
                      <small class="f10">
                        {{ ProdutosRevisadosIA }}
                      </small>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin: 0 0.4rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>Time</strong></small>
                      <small class="f10">{{ ProdutosRevisadosTime }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>

          <div
            class="gb"
            [matMenuTriggerFor]="menuArmazenamento"
            #menuGigasTrigger="matMenuTrigger"
            (click)="handleButtonAnalytics('Visualizar Armazenamento')"
          >
            <small class="f10"><strong>Espaço em Uso</strong></small>
            <mat-progress-bar
              *ngIf="dashboardService.value >= 90"
              class="mat-progress-bar-buffer-limited"
              mode="determinate"
              value="{{ dashboardService.value }}"
            >
            </mat-progress-bar>

            <mat-progress-bar
              *ngIf="!(dashboardService.value >= 90)"
              class="mat-progress-bar-buffer"
              mode="determinate"
              value="{{ dashboardService.value }}"
            >
            </mat-progress-bar>
            <small class="f10" style="float: right"
              >{{ dashboardService.GbUso }}GB/{{
                dashboardService.GbTotal
              }}GB</small
            >
          </div>

          <mat-menu #menuArmazenamento="matMenu" xPosition="before">
            <div *ngIf="isSustentacao" class="billing-container">
              <div class="billing-item">
                <div class="title_notificacao text-center">
                  <h3 style="margin: 0">Armazenamento utilizado</h3>
                  <p>
                    <small>
                      {{ DataHoraUltimaAtualizacao }}
                    </small>
                  </p>
                </div>
                <div style="display: flex; flex-direction: column; gap: 0.5rem">
                  <div class="row">
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 0.5rem 0 1rem"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-left: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>SAT</strong></small>
                        <small class="f10">{{ SAT }}MB</small>
                      </div>
                    </div>
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 1rem 0 0.5rem"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-right: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>CTE</strong></small>
                        <small class="f10">{{ CTE }}MB</small>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 0.5rem 0 1rem; cursor: pointer"
                      (click)="handleInfoNFS()"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-left: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>NFS</strong></small>
                        <small class="f10">{{ NFS }}MB</small>
                      </div>
                    </div>
                    <div
                      class="col-sm-6"
                      data-toggle="collapse"
                      data-target="#collapseStatus5"
                      aria-expanded="false"
                      aria-controls="collapseStatus5"
                      style="padding: 0 1rem 0 0.5rem"
                    >
                      <div
                        style="
                          border: 1px solid #ccc;
                          border-radius: 4px;
                          padding: 5px;
                          margin-right: 1rem;
                          display: flex;
                          flex-direction: column;
                          align-items: center;
                          justify-content: center;
                          background-color: #3f51b5;
                          color: white;
                        "
                      >
                        <small class="f10"><strong>NFC</strong></small>
                        <small class="f10">{{ NFC }}MB</small>
                      </div>
                    </div>
                  </div>
                  <div
                    data-toggle="collapse"
                    data-target="#collapseStatus5"
                    aria-expanded="false"
                    aria-controls="collapseStatus5"
                  >
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin: 0 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>NFE</strong></small>
                      <small class="f10">{{ NFE }}MB</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="billing-item">
                <div class="title_notificacao text-center">
                  <h3 style="margin: 0">Serviços Adicionais utilizados</h3>
                </div>
                <div style="display: flex; flex-direction: column; gap: 0.5rem">
                  <div>
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin: 0 0.4rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"
                        ><strong>Produtos Associados</strong></small
                      >
                      <small *ngIf="ProdutosRevisadosContratado > 0" class="f10"
                        >{{ ProdutosRevisados }} de
                        {{ ProdutosRevisadosContratado }} contratados</small
                      >
                      <small
                        *ngIf="ProdutosRevisadosContratado == 0"
                        class="f10"
                        >{{ ProdutosRevisados }}</small
                      >
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin: 0 0.4rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>NFS Capturadas</strong></small>
                      <small class="f10">{{ NFSCapturadas }}</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!isSustentacao">
              <div class="title_notificacao text-center">
                <h3 style="margin: 0">Armazenamento utilizado</h3>
                <p>
                  <small>
                    {{ DataHoraUltimaAtualizacao }}
                  </small>
                </p>
              </div>
              <div style="display: flex; flex-direction: column; gap: 0.5rem">
                <div class="row">
                  <div
                    class="col-sm-6"
                    data-toggle="collapse"
                    data-target="#collapseStatus5"
                    aria-expanded="false"
                    aria-controls="collapseStatus5"
                    style="padding: 0 0.5rem 0 1rem"
                  >
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin-left: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>SAT</strong></small>
                      <small class="f10">{{ SAT }}MB</small>
                    </div>
                  </div>
                  <div
                    class="col-sm-6"
                    data-toggle="collapse"
                    data-target="#collapseStatus5"
                    aria-expanded="false"
                    aria-controls="collapseStatus5"
                    style="padding: 0 1rem 0 0.5rem"
                  >
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin-right: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>CTE</strong></small>
                      <small class="f10">{{ CTE }}MB</small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col-sm-6"
                    data-toggle="collapse"
                    data-target="#collapseStatus5"
                    aria-expanded="false"
                    aria-controls="collapseStatus5"
                    style="padding: 0 0.5rem 0 1rem; cursor: pointer"
                    (click)="handleInfoNFS()"
                  >
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin-left: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>NFS</strong></small>
                      <small class="f10">{{ NFS }}MB</small>
                    </div>
                  </div>
                  <div
                    class="col-sm-6"
                    data-toggle="collapse"
                    data-target="#collapseStatus5"
                    aria-expanded="false"
                    aria-controls="collapseStatus5"
                    style="padding: 0 1rem 0 0.5rem"
                  >
                    <div
                      style="
                        border: 1px solid #ccc;
                        border-radius: 4px;
                        padding: 5px;
                        margin-right: 1rem;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #3f51b5;
                        color: white;
                      "
                    >
                      <small class="f10"><strong>NFC</strong></small>
                      <small class="f10">{{ NFC }}MB</small>
                    </div>
                  </div>
                </div>
                <div
                  data-toggle="collapse"
                  data-target="#collapseStatus5"
                  aria-expanded="false"
                  aria-controls="collapseStatus5"
                >
                  <div
                    style="
                      border: 1px solid #ccc;
                      border-radius: 4px;
                      padding: 5px;
                      margin: 0 1rem;
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: center;
                      background-color: #3f51b5;
                      color: white;
                    "
                  >
                    <small class="f10"><strong>NFE</strong></small>
                    <small class="f10">{{ NFE }}MB</small>
                  </div>
                </div>
              </div>
            </div>
          </mat-menu>

          <div class="icone pad0">
            <div class="row row-cols-lg-2">
              <div
                mat-icon-button
                (click)="handleOpenNotifications()"
                class="col-12"
                style="margin: 0 -10px; padding: 0"
              >
                <mat-icon>
                  {{ iconnotifica }}
                </mat-icon>
              </div>
              <span
                *ngIf="num_notificacao > 0"
                [matMenuTriggerFor]="menuNotific"
                #menuNotificTrigger="matMenuTrigger"
                class="notnum col-12"
                >{{ num_notificacao }}</span
              >
            </div>
            <mat-menu #menuNotific="matMenu">
              <h3 class="title_notificacao text-center">Notificações</h3>
              <div *ngIf="num_notificacao > 0">
                <button
                  *ngFor="let noti of notificacao"
                  mat-menu-item
                  class="item_notificacao text-center"
                  [title]="noti.assunto"
                  (click)="showmodal('collapseStatus2')"
                  data-toggle="collapse"
                  data-target="#collapseStatus2"
                  aria-expanded="false"
                  aria-controls="collapseStatus2"
                >
                  <mat-icon
                    class="d-flex align-items-center justify-content-center"
                    >lens</mat-icon
                  >
                  <span>{{
                    noti.assunto.length > 35
                      ? noti.assunto.substr(0, 35) + "..."
                      : noti.assunto
                  }}</span>
                </button>
              </div>
              <div *ngIf="num_notificacao <= 0">
                <button mat-menu-item>
                  <span>Você não tem novas notificações</span>
                </button>
              </div>
              <div>
                <button
                  mat-menu-item
                  class="text-center"
                  (click)="showmodal('collapseStatus2')"
                  data-toggle="collapse"
                  data-target="#collapseStatus2"
                  aria-expanded="false"
                  aria-controls="collapseStatus2"
                >
                  <mat-icon>more_horiz</mat-icon>
                </button>
              </div>
            </mat-menu>
          </div>

          <div
            *ngIf="compartilhado"
            class="icone pad0"
            style="cursor: default !important"
          >
            <p>{{ usuario }}</p>
            <p>
              {{
                selectedGestor === ""
                  ? "selecionar" + contabilidade
                  : selectedGestor.substr(0, 30)
              }}
            </p>
          </div>

          <div
            *ngIf="!compartilhado"
            class="icone pad0"
            (click)="openDialogo()"
            title="Alterar {{ contabilidade }}"
          >
            <p>{{ usuario }}</p>
            <p>
              {{
                selectedGestor === ""
                  ? "selecionar" + contabilidade
                  : formatarCNPJ()
              }}
            </p>
          </div>

          <div
            class="icone pad0"
            [matMenuTriggerFor]="modulos"
            (click)="handleButtonAnalytics('Menu Modais')"
          >
            <mat-icon>apps</mat-icon>
          </div>

          <div
            class="icone pad0"
            [matMenuTriggerFor]="menu"
            (click)="handleButtonAnalytics('Menu Perfil')"
          >
            <mat-icon>account_circle</mat-icon>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
  <!-- <mat-toolbar-row style="height: auto; margin: -20px 0 0;">
        <div class="col-lg-3 col-sm-2 col-10">
          <button mat-button [routerLink]="['/revisao-tributaria']" style="font-size: 12px; padding: 0 20px;">Revisão Tributária</button>
        </div>
      </mat-toolbar-row> -->
</mat-toolbar>

<mat-drawer-container class="example-container">
  <mat-drawer
    mode="side"
    #drawer
    class="menulateral"
    id="menulateral"
    *ngIf="showNavBarMenu && showNavbarMenulateral"
    opened
    (mouseover)="changesizefull()"
    (mouseout)="changesizesmall()"
  >
    <spam
      mat-menu-item
      *ngIf="auditoria"
      class="default menuactive2 menuinactive"
      style="display: none"
    >
      <mat-icon>visibility</mat-icon>
      <span style="display: none"> Auditoria</span>
    </spam>
    <div class="auditoria">
      <button
        mat-menu-item
        *ngIf="auditoria"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="
          acessoAnalytics('Acesso Submodulo Auditar Documentos'); clique()
        "
        [routerLink]="[pathAuditoria[0]]"
      >
        <mat-icon>description</mat-icon>
        <span style="display: none"> Auditar Documentos</span>
      </button>

      <button
        mat-menu-item
        *ngIf="auditoria && isSustentacao && isImpostocerto"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="acessoAnalytics('Acesso Submodulo Confrontar POC'); clique()"
        routerLink="confrontar-poc"
      >
        <mat-icon>verified_user</mat-icon>
        <span style="display: none">Confrontar POC</span>
      </button>
      <button
        mat-menu-item
        *ngIf="auditoria"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="
          acessoAnalytics(
            'Acesso Submódulo Redefinir Atividade de Participantes'
          );
          clique()
        "
        [routerLink]="['/participante-emitente']"
      >
        <mat-icon>edit_note</mat-icon>
        <span style="display: none">Redefinir Atividade de Participantes</span>
      </button>
      <button
        mat-menu-item
        *ngIf="auditoria"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="
          acessoAnalytics('Acesso Submódulo Definir Cenario Próprio'); clique()
        "
        [routerLink]="['/cenarios']"
      >
        <mat-icon>post_add</mat-icon>
        <span style="display: none"> Definir Cenario Próprio</span>
      </button>
      <button
        mat-menu-item
        *ngIf="auditoria"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="
          acessoAnalytics('Acesso Submódulo Relacionar-produto-emitente');
          clique()
        "
        [routerLink]="['/produto-emitente']"
      >
        <mat-icon>crop_rotate</mat-icon>
        <span style="display: none"> Relacionar Produto Emitente</span>
      </button>
    </div>
    <mat-divider></mat-divider>
    <spam
      mat-menu-item
      *ngIf="revisao"
      class="default menuactive2 menuinactive"
      style="display: none"
    >
      <mat-icon>filter_alt</mat-icon>
      <span style="display: none">Revisão Tributária</span>
    </spam>
    <div class="revisao">
      <button
        mat-menu-item
        *ngIf="revisao"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="acessoAnalytics('revisao-tributaria'); clique()"
        [routerLink]="['/revisao-tributaria']"
      >
        <mat-icon>ballot</mat-icon>
        <span style="display: none">Revisar Produtos</span>
      </button>
    </div>

    <button
      mat-menu-item
      *ngIf="revisao && isImpostocerto"
      class="menuactive text-center sub"
      (click)="clique()"
      [routerLink]="['/dash-modulo-integracao']"
    >
      <mat-icon>widgets</mat-icon>
      <span style="display: none">Módulo de Integração</span>
    </button>

    <spam
      mat-menu-item
      *ngIf="consulta"
      class="default menuactive2 menuinactive"
      style="display: none"
    >
      <mat-icon>grain</mat-icon>
      <span style="display: none">Consultas</span>
    </spam>

    <button
      mat-menu-item
      *ngIf="consulta"
      class="menuactive text-center sub menuinactive"
      style="display: none"
      (click)="acessoAnalytics('consulta-livre-de-produtos'); clique()"
      [routerLink]="['/consulta-livre-de-produtos']"
    >
      <mat-icon>travel_explore</mat-icon>
      <span style="display: none">Consulta Livre de Produtos</span>
    </button>

    <div *ngIf="usuarioproibido">
      <button
        mat-menu-item
        *ngIf="consulta"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="acessoAnalytics('simulador-de-operacoes'); clique()"
        [routerLink]="['/simulador-de-operacoes']"
      >
        <mat-icon>style</mat-icon>
        <span style="display: none">Simulador de Operações</span>
      </button>
    </div>

    <spam
      mat-menu-item
      *ngIf="segregacao && possuiSegregacao"
      class="default menuactive2 menuinactive"
      style="display: none"
    >
      <mat-icon>apps</mat-icon>
      <span style="display: none">Segregação</span>
      <!-- <span style="display: none">PGDAS</span> -->
    </spam>

    <button
      mat-menu-item
      *ngIf="segregacao && possuiSegregacao"
      class="menuactive text-center sub menuinactive"
      style="display: none"
      (click)="acessoAnalytics('Segregação'); clique()"
      [routerLink]="['/segregacao']"
    >
      <mat-icon>calculate</mat-icon>
      <span style="display: none">Segregação Simples Nacional</span>
    </button>

    <button
      mat-menu-item
      *ngIf="segregacao && possuiSegregacao"
      class="menuactive text-center sub menuinactive"
      style="display: none"
      (click)="acessoAnalytics('Acesso Submodulo PGDAS'); clique()"
      [routerLink]="['/pgdas']"
    >
      <mat-icon>class</mat-icon>
      <span style="display: none">Emissão PGDAS</span>
    </button>

    <spam
      mat-menu-item
      *ngIf="emalote"
      class="default menuactive2 menuinactive"
      style="display: none"
    >
      <mat-icon>card_travel</mat-icon>
      <span style="display: none">Emalote</span>
    </spam>
    <div class="emalote">
      <button
        mat-menu-item
        *ngIf="emalote"
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="clique(); acessoAnalytics('Acesso Submodulo Emalote Web')"
        [routerLink]="['/emalote']"
      >
        <mat-icon>graphic_eq</mat-icon>
        <span style="display: none">Emalote Web</span>
      </button>
      <button
        *ngIf="emalote"
        mat-menu-item
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="acessoAnalytics('Acesso Submodulo Visão Situacional')"
        [routerLink]="['/visao-situacional']"
      >
        <mat-icon>remove_red_eye</mat-icon>
        <span style="display: none">Visão Situacional</span>
      </button>
      <button
        *ngIf="emalote"
        mat-menu-item
        class="menuactive text-center sub menuinactive"
        style="display: none"
        (click)="acessoAnalytics('Acesso Sumodulo Emalote Cockpit Vendas')"
        [routerLink]="['/cockpit-vendas']"
      >
        <mat-icon>av_timer</mat-icon>
        <span style="display: none">CockPit de Vendas</span>
      </button>
      <button
        *ngIf="emalote"
        mat-menu-item
        class="menuactive text-center sub menuinactive"
        (click)="acessoAnalytics('Acesso Submodulo Nfs Tomadas')"
        [routerLink]="['/nfs-tomadas']"
      >
        <mat-icon>power</mat-icon>
        <span style="display: none">Integração NFS Tomadas</span>
      </button>
      <button
        *ngIf="emalote"
        mat-menu-item
        class="menuactive text-center sub menuinactive"
        (click)="acessoAnalytics('Acesso Submodulo Calculo de Guias')"
        [routerLink]="['/calcular-guia']"
      >
        <mat-icon>calculate</mat-icon>
        <span style="display: none">Calculo de Guias</span>
      </button>
      <button
        *ngIf="emalote"
        mat-menu-item
        class="menuactive text-center sub menuinactive"
        (click)="acessoAnalytics('Acesso Submodulo Confrontar SPED')"
        [routerLink]="['/confrontar-sped']"
      >
        <fa-icon
          [icon]="faScaleBalanced"
          class="fa-lg icon"
          title="Confrontar SPED"
        ></fa-icon>
        <span style="display: none">Confrontar SPED</span>
      </button>
    </div>

    <spam
      mat-menu-item
      *ngIf="recuperacao"
      class="default menuactive2 menuinactive"
      style="display: none"
    >
      <mat-icon>refresh</mat-icon>
      <span style="display: none">Recuperação Simples Nacional</span>
    </spam>

    <button
      *ngIf="recuperacao"
      mat-menu-item
      class="menuactive text-center sub menuinactive"
      style="display: none"
      (click)="acessoAnalytics('Acesso Submodulo Contratar Pacote')"
      [routerLink]="['/recuperacao-simples-nacional']"
    >
      <mat-icon>refresh</mat-icon>
      <span style="display: none">Recuperação SN</span>
    </button>

    <button
      *ngIf="recuperacao"
      mat-menu-item
      class="menuactive text-center sub menuinactive"
      style="display: none"
      (click)="acessoAnalytics('Acesso Submodulo Contratar Pacote')"
      [routerLink]="['/contratar-pacote']"
    >
      <mat-icon>payment</mat-icon>
      <span style="display: none">Contratar Pacote</span>
    </button>

    <spam mat-menu-item class="default menuactive2" *ngIf="!compartilhado">
      <mat-icon>domain</mat-icon>
      <span style="display: none">Gerenciamento de Contribuintes</span>
    </spam>

    <button
      mat-menu-item
      class="menuactive text-center sub"
      (click)="acessoAnalytics('Acesso Meus Contribuintes')"
      [routerLink]="['/meus-contribuintes']"
      [state]="{ id: module }"
      *ngIf="!compartilhado"
    >
      <mat-icon>swap_horizontal_circle</mat-icon>
      <span style="display: none">Meus Contribuintes</span>
    </button>
    <button
      mat-menu-item
      class="menuactive text-center sub"
      (click)="acessoAnalytics('Acesso Usuário Contribuinte')"
      [routerLink]="['/usuario-contribuinte']"
      *ngIf="!compartilhado && !isImpostocerto"
    >
      <mat-icon>supervised_user_circle</mat-icon>
      <span style="display: none">Usuários Contribuintes</span>
    </button>
    <button
      mat-menu-item
      class="menuactive text-center sub"
      (click)="acessoAnalytics('Acesso Meus Certificados')"
      [routerLink]="['/meus-certificados']"
      *ngIf="!compartilhado"
    >
      <mat-icon>notes</mat-icon>
      <span style="display: none">Gestão de Certificados</span>
    </button>
  </mat-drawer>
  <mat-drawer-content id="menucont" class="close_menu">
    <div id="over"></div>
    <div class="app-content">
      <!-- Alerta de manutentação -->

      <!-- <div *ngIf="manutencao" class="alert alert-danger" role="alert">
        Querido cliente! estamos passando por um período de instabilidade, nosso
        time já está realizando a manutenção para melhor atendê-lo. Isso pode
        afetar a usabilidade da ferramenta. Qualquer urgência pedimos por
        gentileza que nos acione via chat!
      </div> -->
      <div
        *ngIf="
          dataInadinplencia &&
          !dataFalhaPagamento &&
          pathName != '/financeiro' &&
          isValidRoute() &&
          !isGestorDemo
        "
        class="alert alert-danger"
        role="alert"
      >
        Seu pagamento está atrasado, evite o bloqueio de conta. Acesse
        <a
          [routerLink]="['/financeiro']"
          *ngIf="(!isSustentacao || !prod) && nivel"
          class="alert-link"
          >aqui</a
        ><a *ngIf="isSustentacao" class="alert-link">aqui</a> para regularizar
        sua assinatura.
      </div>

      <div
        *ngIf="
          dataFalhaPagamento &&
          pathName != '/financeiro' &&
          isValidRoute() &&
          !isGestorDemo
        "
        class="alert alert-warning"
        role="alert"
      >
        Seu pagamento foi recusado, evite o bloqueio de conta. Para manter sua
        assinatura, acesse
        <a
          [routerLink]="['/financeiro']"
          *ngIf="!isSustentacao && nivel"
          class="alert-link"
          >aqui</a
        ><a *ngIf="isSustentacao" class="alert-link">aqui</a> e atualize sua
        froma de pagamento.
      </div>

      <div
        *ngIf="
          pagamentoPendente &&
          !dataInadinplencia &&
          !dataFalhaPagamento &&
          pathName != '/financeiro' &&
          isValidRoute() &&
          !isGestorDemo
        "
        class="alert alert-warning"
        role="alert"
      >
        Seu boleto já está disponível e vence em
        {{ dataPagamento | date : "dd/MM/yyyy" }}. Evite o bloqueio de conta,
        para pagar seu boleto acesse
        <a
          [routerLink]="['/financeiro']"
          *ngIf="!isSustentacao && nivel"
          class="alert-link"
          >aqui</a
        ><a *ngIf="isSustentacao" class="alert-link">aqui</a>.
      </div>

      <div
        *ngIf="showNavBarMenu && showNavbarMenulateral && showPathButtons"
        class="container-visible-button"
      >
        <!--Botões de atalho-->
        <div class="row col-sm-12 visible-button" *ngIf="auditoria">
          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathAuditoria.includes(pathName) ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 0 22.916%;
              max-width: 22.916%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Auditoria')"
            [routerLink]="[pathAuditoria[0]]"
          >
            <mat-icon
              class="mr-2 {{
                pathAuditoria.includes(pathName) ? 'mat-icon-checked' : ''
              }}"
              >description</mat-icon
            >
            <span>Auditar Documentos</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/participante-emitente' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 0 22.916%;
              max-width: 22.916%;
              font-size: 0.8rem;
              justify-content: center;
            "
            (click)="
              acessoAnalytics(
                'Acesso Submodulo Redefinir Atividade de Participantes'
              )
            "
            [routerLink]="['/participante-emitente']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/participante-emitente' ? 'mat-icon-checked' : ''
              }}"
              >edit_note</mat-icon
            >
            <span>Redefinir Atividade de Participantes</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/cenarios' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 0 22.916%;
              max-width: 22.916%;
              justify-content: center;
            "
            (click)="
              acessoAnalytics('Acesso Submodulo Definir Cenário Próprio')
            "
            [routerLink]="['/cenarios']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/cenarios' ? 'mat-icon-checked' : ''
              }}"
              >post_add</mat-icon
            >
            <span>Definir Cenário Próprio</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/produto-emitente' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 0 22.916%;
              max-width: 22.916%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Submodulo Produto Emitente')"
            [routerLink]="['/produto-emitente']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/produto-emitente' ? 'mat-icon-checked' : ''
              }}"
              >crop_rotate</mat-icon
            >
            <span>Relacionar Produto Emitente</span>
          </button>
        </div>

        <div class="row col-sm-12 visible-button" *ngIf="emalote">
          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/emalote' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 022.916%;
              max-width: 22.916%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Emalote')"
            [routerLink]="['/emalote']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/emalote' ? 'mat-icon-checked' : ''
              }}"
              >graphic_eq</mat-icon
            >
            <span>Emalote Web</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/visao-situacional' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 022.916%;
              max-width: 22.916%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Submodulo Visão Situacional')"
            [routerLink]="['/visao-situacional']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/visao-situacional' ? 'mat-icon-checked' : ''
              }}"
              >remove_red_eye</mat-icon
            >
            <span>Visão Situacional</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/cockpit-vendas' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 022.916%;
              max-width: 22.916%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Submodulo CockPit de Vendas')"
            [routerLink]="['/cockpit-vendas']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/cockpit-vendas' ? 'mat-icon-checked' : ''
              }}"
              >av_timer</mat-icon
            >
            <span>CockPit de Vendas</span>
          </button>

          <button
            *ngIf="!isImpostocerto"
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/nfs-tomadas' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 22.916%;
              flex: 0 022.916%;
              max-width: 22.916%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Submodulo NFS Tomadas')"
            [routerLink]="['/nfs-tomadas']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/nfs-tomadas' ? 'mat-icon-checked' : ''
              }}"
              >power</mat-icon
            >
            <span>NFS Tomadas</span>
          </button>
        </div>

        <div
          class="row col-sm-12 visible-button"
          *ngIf="segregacao && possuiSegregacao"
        >
          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/segregacao' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 45.83%;
              flex: 0 0 45.83%;
              max-width: 45.83%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Segregação')"
            [routerLink]="['/segregacao']"
          >
            <mat-icon
              class="mr-2  {{
                pathName == '/segregacao' ? 'mat-icon-checked' : ''
              }}"
              >calculate</mat-icon
            >
            <span>Segregação Simples Nacional</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 {{
              pathName == '/pgdas' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 45.83%;
              flex: 0 0 45.83%;
              max-width: 45.83%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Submodulo PGDAS')"
            [routerLink]="['/pgdas']"
          >
            <mat-icon
              class="mr-2  {{ pathName == '/pgdas' ? 'mat-icon-checked' : '' }}"
              >class</mat-icon
            >
            <span>Emissão PGDAS</span>
          </button>
        </div>

        <div class="row col-sm-12 visible-button" *ngIf="consulta">
          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 mb-2 {{
              pathName == '/consulta-livre-de-produtos'
                ? 'mat-button-checked'
                : ''
            }}"
            style="
              width: 45.83%;
              flex: 0 0 45.83%;
              max-width: 45.83%;
              justify-content: center;
            "
            (click)="
              acessoAnalytics('Acesso Consulta Livre de Impostos de Produtos')
            "
            [routerLink]="['/consulta-livre-de-produtos']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/consulta-livre-de-produtos'
                  ? 'mat-icon-checked'
                  : ''
              }}"
              >travel_explore</mat-icon
            >
            <span>Consulta Livre de Produtos</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 mb-2 {{
              pathName == '/simulador-de-operacoes' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 45.83%;
              flex: 0 0 45.83%;
              max-width: 45.83%;
              justify-content: center;
            "
            (click)="acessoAnalytics('Acesso Submodulo Simulador de Operações')"
            [routerLink]="['/simulador-de-operacoes']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/simulador-de-operacoes' ? 'mat-icon-checked' : ''
              }}"
              >style</mat-icon
            >
            <span>Simulador de Operações</span>
          </button>
        </div>

        <div
          class="row col-sm-12 visible-button py-2"
          *ngIf="revisao && isImpostocerto"
        >
          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 mb-2 {{
              pathName == '/revisao-tributaria' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 45.83%;
              flex: 0 0 45.83%;
              max-width: 45.83%;
              justify-content: center;
            "
            [routerLink]="['/revisao-tributaria']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/revisao-tributaria' ? 'mat-icon-checked' : ''
              }}"
              >ballot</mat-icon
            >
            <span>Revisar Produtos</span>
          </button>

          <button
            mat-raised-button
            class="menuactive text-center sub col-sm-1 mb-2 {{
              pathName == '/dash-modulo-integracao' ? 'mat-button-checked' : ''
            }}"
            style="
              width: 45.83%;
              flex: 0 0 45.83%;
              max-width: 45.83%;
              justify-content: center;
            "
            [routerLink]="['/dash-modulo-integracao']"
          >
            <mat-icon
              class="mr-2 {{
                pathName == '/dash-modulo-integracao' ? 'mat-icon-checked' : ''
              }}"
              >widgets</mat-icon
            >
            <span>Módulo de Integração</span>
          </button>
        </div>
      </div>
      <router-outlet></router-outlet>
    </div>
    <app-footer></app-footer>
  </mat-drawer-content>
</mat-drawer-container>

<mat-menu #menu="matMenu" class="menu" style="z-index: 10000">
  <button
    *ngIf="nivel"
    class="menuactive"
    mat-menu-item
    (click)="acessoAnalytics('Acesso Minhas Contabilidades')"
    [routerLink]="[urlmeus]"
  >
    <mat-icon>request_page</mat-icon>
    <span>{{ MinhasContabilidades }}</span>
  </button>
  <button
    mat-menu-item
    class="menuactive"
    *ngIf="nivel || isImpostocerto"
    (click)="acessoAnalytics('Acesso Usuários da Contabilidade')"
    [routerLink]="[urlusuario]"
  >
    <mat-icon>contact_page</mat-icon>
    <span>Usuários {{ daContabilidade }}</span>
  </button>
  <button
    mat-menu-item
    class="menuactive"
    *ngIf="!compartilhado && !isImpostocerto"
    (click)="acessoAnalytics('Acesso integrações')"
    [routerLink]="['/integracoes']"
  >
    <mat-icon>style</mat-icon>
    <span>Integrações</span>
  </button>
  <button
    mat-menu-item
    class="menuactive"
    *ngIf="!compartilhado"
    (click)="acessoAnalytics('Acesso Plugin de Captura')"
    [routerLink]="['/plugin-captura']"
  >
    <mat-icon>dns</mat-icon>
    <span>Plugin de Captura</span>
  </button>
  <button
    mat-menu-item
    class="menuactive"
    *ngIf="!compartilhado"
    (click)="acessoAnalytics('Acesso Meus dados')"
    [routerLink]="['/meus-dados']"
  >
    <mat-icon>account_circle</mat-icon>
    <span>Meus Dados</span>
  </button>
  <button
    *ngIf="
      imposto == 0 && !compartilhado && (!isSustentacao || !isProd) && nivel
    "
    class="menuactive"
    mat-menu-item
    (click)="acessoAnalytics('Acesso Financeiro')"
    [routerLink]="['/financeiro']"
  >
    <mat-icon>account_balance_wallet</mat-icon>
    <span>Financeiro</span>
  </button>
  <button mat-menu-item class="menuactive" (click)="logout()">
    <mat-icon>power_settings_new</mat-icon>
    <span>Sair</span>
  </button>
</mat-menu>

<mat-menu #modulos="matMenu" class="menu" style="z-index: 10000">
  <div
    style="
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
      margin: 0 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.3rem;
      max-width: 92% !important;
    "
  >
    <div
      class="d-flex col-sm-12 align-items-center justify-content-center"
      style="border-bottom: 1px solid #ccc"
    >
      <small class="f10 m-2">
        <strong>Plano contratado:</strong>
      </small>
      <h2 class="m-0">
        {{ Descricao }}
      </h2>
    </div>
    <div class="d-flex col-sm-12 flex-column">
      <small class="f10 m-2" style="text-align: center">
        <strong>Limites de associação:</strong>
      </small>
      <div class="col-sm-12 d-flex flex-column p-0">
        <h3 class="m-0">
          Eans válidos:
          <strong>{{ LimiteAssociacaoEanValido | number : "" }}</strong>
        </h3>
        <h3 class="m-0">
          Eans inválidos/não EAN:
          <strong>{{ LimiteAssociacaoEanInvalido | number : "" }}</strong>
        </h3>
      </div>
    </div>
  </div>
  <div
    class="row col-sm-12"
    style="
      margin: 0;
      display: flex;
      justify-content: center;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 5px;
      margin: 0.5rem 1rem 0rem;
      max-width: 92% !important;
      flex-direction: column;
    "
  >
    <div
      class="d-flex p-0 align-items-center"
      style="border-bottom: 1px solid #ccc"
    >
      <div
        class="module pointer col-sm-3"
        [routerLink]="[pathAuditoria[0]]"
        *ngIf="!compartilhado"
        (click)="acessoAnalytics('Acesso Auditoria')"
      >
        <div class="processos-box background3">
          <mat-icon style="color: white !important" class="icon">
            description
          </mat-icon>
        </div>
      </div>
      <div class="col-sm-9 p-0">
        <strong style="font-size: 14px">Auditoria</strong>
        <p>Contribuintes: {{ LimiteContribuinteAuditoria }}</p>
      </div>
    </div>

    <div
      class="d-flex p-0 align-items-center"
      style="border-bottom: 1px solid #ccc"
    >
      <div
        *ngIf="modulosDisponiveis.includes('3')"
        class="module pointer col-sm-3"
        [routerLink]="['/revisao-tributaria']"
        (click)="acessoAnalytics('Acesso Revisão')"
      >
        <div class="processos-box background4">
          <mat-icon style="color: white !important" class="icon"
            >ballot</mat-icon
          >
        </div>
      </div>
      <div class="col-sm-9 p-0">
        <strong style="font-size: 14px">Revisão</strong>
        <p>Contribuintes: {{ LimiteContribuinteRevisao }}</p>
      </div>
    </div>

    <div
      class="d-flex p-0 align-items-center"
      style="border-bottom: 1px solid #ccc"
    >
      <div
        class="module pointer col-sm-3"
        *ngIf="usuarioproibido && !compartilhado && possuiSegregacao"
        [routerLink]="['/segregacao']"
        (click)="acessoAnalytics('Acesso Segregação')"
      >
        <div class="processos-box background2">
          <mat-icon style="color: white !important" class="icon"
            >calculate</mat-icon
          >
        </div>
      </div>
      <div class="col-sm-9 p-0">
        <strong style="font-size: 14px">Segregação</strong>
        <p>Contribuintes: {{ LimiteContribuinteSegregacao }}</p>
      </div>
    </div>

    <div
      class="d-flex p-0 align-items-center"
      style="border-bottom: 1px solid #ccc"
    >
      <div
        *ngIf="modulosDisponiveis.includes('6')"
        class="module pointer col-sm-3"
        [routerLink]="['/recuperacao-simples-nacional']"
        (click)="acessoAnalytics('Acesso Recuperação Simples Nacional')"
      >
        <div class="processos-box background3">
          <mat-icon style="color: white !important" class="icon"
            >refresh</mat-icon
          >
        </div>
      </div>
      <div class="col-sm-9 p-0">
        <strong style="font-size: 14px">Recuperação</strong>
        <p>Saldo de recuperações: {{ LimiteRecuperacaoSaldo }}</p>
      </div>
    </div>

    <div
      class="d-flex p-0 align-items-center"
      style="border-bottom: 1px solid #ccc"
    >
      <div
        *ngIf="modulosDisponiveis.includes('5')"
        class="module pointer col-sm-3"
        [routerLink]="['/consulta-livre-de-produtos']"
        (click)="acessoAnalytics('Acesso Consulta Livre')"
      >
        <div class="processos-box background1">
          <mat-icon style="color: white !important" class="icon"
            >travel_explore</mat-icon
          >
        </div>
      </div>
      <div class="col-sm-9 p-0">
        <strong style="font-size: 14px">Consulta</strong>
        <p style="font-size: 13px; margin: 0 !important">
          Consultas disponíveis: {{ LimiteConsulta }}
        </p>
        <p style="font-size: 13px; margin: 0 !important">
          Simulações disponíveis: {{ LimiteSimulacao }}
        </p>
      </div>
    </div>

    <div class="d-flex p-0 align-items-center">
      <div
        *ngIf="modulosDisponiveis.includes('1')"
        class="module pointer col-sm-3"
        [routerLink]="['/emalote']"
        (click)="acessoAnalytics('Acesso E-malote')"
      >
        <div class="processos-box background3">
          <mat-icon style="color: white !important" class="icon"
            >card_travel</mat-icon
          >
        </div>
      </div>
      <div class="col-sm-9 p-0">
        <strong style="font-size: 14px">e-Malote</strong>
        <p style="font-size: 13px; margin: 0 !important">
          Limite na guarda de documentos: {{ LimiteEmaloteDias }} dias
        </p>
        <p style="font-size: 13px; margin: 0 !important">
          espaço disponível: {{ LimiteEmaloteEspaco }}GB
        </p>
      </div>
    </div>
  </div>
</mat-menu>

<div class="full" (click)="hidemodal()" style="display: none"></div>
<div
  class="margin-center col-sm-12 mat-elevation-z8 modalNotific"
  style="display: none"
>
  <div class="col-sm-12 collapse" id="collapseStatus2">
    <div class="modal-header">
      <h2>NOTIFICAÇÕES</h2>
      <button
        type="button"
        class="close"
        (click)="hidemodal()"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <mat-tab-group>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>mail</mat-icon>
          Não Lidas
        </ng-template>
        <div class="row col-sm-12 align-items-center">
          <div class="col-sm-3">
            <button
              mat-raised-button
              class="botao w-100 d-flex justify-content-center align-items-center"
              color="primary"
              (click)="MarcarLidoTodas()"
              style="font-size: 1rem"
            >
              <fa-icon
                [icon]="faCheckDouble"
                class="fa-lg icon"
                title="Marcar como lidas"
              ></fa-icon>
              Marcar como lidas
            </button>
          </div>
          <div class="col-sm-3"></div>
          <div class="col-sm-6">
            <mat-form-field>
              <mat-label>Filtrar Resultados</mat-label>
              <input
                matInput
                (keyup)="apFilterNotNLidos($event)"
                placeholder="Pesquisar por ..."
                #input
              />
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-12">
          <table
            mat-table
            [dataSource]="Notificacoes"
            #ordernarNotNLidos="matSort"
            matSort
            class="table"
          >
            <ng-container matColumnDef="id_Notificacao">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                #
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="text-center"
                title="Marcar como Lido"
                (click)="MarcarLido(row)"
              >
                <mat-icon>mail</mat-icon>
              </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="dataHora">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                Data/Hora
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.dataHora.slice(0, -6) }}
              </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="assunto">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                Assunto
              </th>
              <td mat-cell *matCellDef="let row">{{ row.assunto }}</td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="texto">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                Mensagem
              </th>
              <td mat-cell *matCellDef="let row">{{ row.texto }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="ColumnsNot"></tr>
            <tr mat-row *matRowDef="let row; columns: ColumnsNot"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">
                Não foram encontrados registros
              </td>
            </tr>
          </table>
          <mat-paginator
            #paginarNotNLidos
            [pageSize]="100"
            [pageSizeOptions]="[25, 30, 40, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>drafts</mat-icon>
          Lidas
        </ng-template>
        <div class="row col-sm-12">
          <div class="col-sm-6"></div>
          <div class="col-sm-6">
            <mat-form-field>
              <mat-label>Filtrar Resultados</mat-label>
              <input
                matInput
                (keyup)="apFilterNotLidos($event)"
                placeholder="Pesquisar por ..."
                #input
              />
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm-12">
          <table
            mat-table
            [dataSource]="NotificacoesLidos"
            #ordernarNotLidos="matSort"
            matSort
            class="table"
          >
            <ng-container matColumnDef="id_Notificacao">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                #
              </th>
              <td
                mat-cell
                *matCellDef="let row"
                class="text-center"
                title="Lido"
              >
                <mat-icon>drafts</mat-icon>
              </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="dataHora">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                Data/Hora
              </th>
              <td mat-cell *matCellDef="let row">
                {{ row.dataHora.slice(0, -6) }}
              </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="assunto">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                Assunto
              </th>
              <td mat-cell *matCellDef="let row">{{ row.assunto }}</td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="texto">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                [resizeColumn]="true"
              >
                Mensagem
              </th>
              <td mat-cell *matCellDef="let row">{{ row.texto }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="ColumnsNotLidos"></tr>
            <tr mat-row *matRowDef="let row; columns: ColumnsNotLidos"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="3">
                Não foram encontrados registros
              </td>
            </tr>
          </table>
          <mat-paginator
            #paginarNotLidos
            [pageSizeOptions]="[25, 30, 40, 50, 100]"
            showFirstLastButtons
          ></mat-paginator>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
