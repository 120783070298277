import { EnvironmentModel } from 'src/environments/environment.model';

export const environment: EnvironmentModel = {
  production: false,
  URL: 'http://localhost:8000/api/',
  baseURL: 'https://legacy.impostograma.com.br/server/public/api/',
  proxy: 'https://legacy.impostograma.com.br/server/public/',
  APICerto: 'https://api.impostograma.com.br/ImpostoCerto/api/',
  API: 'https://api.impostograma.com.br/ImpostoGrama/api/',
  Emalote: 'https://api.impostograma.com.br/IGeMaloteWeb',
  AWSIntegrations: 'https://services.impostograma.com.br/integrations-prod',
  AWSImposto: 'https://services.impostograma.com.br/impostograma-prod',
  AWSUploads: 'https://services.impostograma.com.br/uploads-prod',
  AWSIa: 'https://services.impostograma.com.br/ia-prod',
  APP: 'impostograma',
  APPSite: 'impostograma',
  AWS: 'https://services.impostograma.com.br/',
  AWSAuth: 'RRwPrJsGdiwdWZ1CZj9srRtCdQ99LPeg',
  SuperLogicaToken: '6d0e03e4-1d48-44c2-bb60-71e762cad269',
  SuperLogicaAccessToken: 'e9b07a9d-9ec5-4cf6-b33b-9f77117c0eff',
  SuperlogicaDomain: 'impostograma',
  Ambiente: 'prod',
  gaTag: 'G-RF9R063D88',
  auditoria: 'auditoria',
  nfstomadas: 'nfs-tomadas',
  Contabilidade: 'Contabilidade',
  contabilidade: 'contabilidade',
  aContabilidade: 'à Contabilidade',
  umaContabilidade: 'uma Contabilidade',
  daContabilidade: 'da Contabilidade',
  dasContabilidades: 'das Contabilidade',
  nasContabilidades: 'na(s) contabilidade(s)',
  contratarPacote: 'contratar-pacote',
  dashmodulointegracao: 'dash-modulo-integracao',
  emailAtendimento: 'atendimento@impostograma.com.br',
  emailDemo: 'demo@impostograma.com.br',
  emailSustentacao: 'sustentacao@impostograma.com.br',
  emailSustentacaoComercial: 'sustentacao.comercial@impostograma.com.br',
  emailSuporte: 'suporte@impostograma.com.br',
  emailTributario: 'tributario@impostograma.com.br',
  Trial: 'trial',
  suporteauditoria: 'auditoria-suporte',
  site: 'https://impostograma.com.br',
  gerenciarprocessamentos: 'gerenciar-processamentos',
  IGAuth: 'YT1iNjdiZGY2My1iNTFlLTQzODMtODM0NC03OTFjNDE1OTU0OGIsNzE9MTMy',

  meusgestores: 'gestores',
  modulointegracao: 'modulo-integracao',
  BucketUpload: 'upload-ig-files',
  Bucket: 'emalote',
  BucketTMP: 'tmp-process',
  mensagemreativar: 'Essa Contabilidade já está cadastrada, deseja reativá-la?',
  Contabilidadeatualizar:
    'Essa contabilidade já está cadastrada, deseja atualizá-la ?',
  mensagemjacadastradoContabilidade:
    'Ops! Essa Contabilidade já está cadastrada em outra conta!',
  selecionarcontabilidade: 'selecionar contabilidade',
  SelecionarumaContabilidade: 'Selecione uma Contabilidade!',
  continuarCadastrar: 'Para continuar você deve cadastrar uma contabilidade',
  messagemdeletarusuario:
    'Por favor selecione a(s) contabilidade(s) em que você deseja deletar o usuário:',
  titleContabilidade: 'Complete o Cadastro da Contabilidade',
  MinhasContabilidades: 'Minhas Contabilidades',
  rotaminhascontabilidades: 'minhas-contabilidade',
  rotaadicionarcontabilidade: 'adicionar-contabilidade',
  rotausuariocontabilidade: 'usuario-contabilidade',
  ContabilidadeAlteradaComSucesso: 'Contabilidade alterada com sucesso!',
  messagecadastro: 'Contabilidade cadastrada com Sucesso!',
  novaconta: 'nova-conta',
  outracontabilidade: 'outra Contabilidade',
  login: 'login',
  meuscontribuintes: 'meus-contribuintes',
  adicionarcontribuintes: 'adicionar-contribuintes',
  atualizarcontribuintes: 'atualizar-contribuintes',
  ativarconta: 'ativar-conta',
  meuscertificados: 'meus-certificados',
  adicionarcertificado: 'adicionar-certificado',
  resetsenha: 'reset-senha',
  usuariocontribuinte: 'usuario-contribuinte',
  meusdados: 'meus-dados',
  plugincaptura: 'plugin-captura',
  revisaotributaria: 'revisao-tributaria',
  home: 'home',
  retorno: 'retorno',
  autenticar: 'autenticar',
  financeiro: 'financeiro',
  recuperarconta: 'recuperar-conta',
  recuperarsenha: 'recuperar-senha',
  novasenha: 'nova-senha',
  comece: 'comece',
  formularioPGDAS: 'formulario-pgdas',
  etapa: 'etapa',
  relatorio: 'relatorio-auditoria',
  produtoemitente: 'produto-emitente',
  participanteemitente: 'participante-emitente',
  cenarios: 'cenarios',
  referencial: 'referencial',
  download: 'download',
  consultalivredeprodutos: 'consulta-livre-de-produtos',
  simuladordeoperacoes: 'simulador-de-operacoes',
  confrontarsped: 'confrontar-sped',
  calcularguia: 'calcular-guia',
  segregacao: 'segregacao',
  pgdas: 'pgdas',
  verdocumento: 'ver-documento',
  consultainterestadual: 'consulta-interestadual',
  emalote: 'emalote',
  cacheBucket: 'imposto-cache',
  emalotedetails: 'emalote-details',
  visaosituacional: 'visao-situacional',
  ID_Servico_Recuperacao: 17,
  gerenciarintegracoes: 'gerenciar-integracoes',
  SubTituloUpgrade: 'Módulo cancelado ou não contemplado no seu plano',
  TextoUpgrade:
    'Para acessar esse módulo por favor faça upgrade de plano ou reative o seu contrato',
  TextoContratar: 'Para acessar esse módulo, contrate pacotes adicionais',
  AWS_ACCESS_KEY_ID: 'AKIA4HUMI5ERAMATEVJ6',
  AWS_SECRET_ACCESS_KEY: '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',
  AWS_REGION: 'sa-east-1',
  modulos: {
    emalote: '1',
    auditoria: '2',
    revisao: '3',
    segregacao: '4',
    consulta: '5',
    recuperacao: '6',
  },
  modulosTitle: {
    1: ' e-Malote Web',
    2: ' Auditoria',
    3: ' Revisão',
    4: ' Segregação',
    5: ' Consulta Livre',
    6: ' Recuperação',
  },

  tituloModuloAuditoria: 'Auditar Documentos Fiscais Eletrônicos',
  tituloModuloSegregacao: 'Segregação Simples Nacional',
  tituloModuloConsulta: 'Consulta Livre de Impostos de Produtos',
  tituloModuloEmalote: 'e-Malote',
  tituloModuloRevisao: 'Revisar Cadastro de Impostos de Produtos',

  tituloBoxModuloAuditoria: 'AuditoGrama',
  tituloBoxModuloSegregacao: 'Segregar',
  tituloBoxModuloConsulta: 'ConsultoGrama',
  tituloBoxModuloEmalote: 'e-Malote',
  tituloBoxModuloRevisao: 'RevisoGrama',
  tituloBoxModuloRecuperacao: 'Recuperação',

  userImposto: 'userImpostoBR',
  senhaImposto: 'Im@st@10',
  recuperacao: 'recuperacao',

  pluginCaptura: 'plugin-de-captura-ig',

  tipo : {
    55: 'NFEXML',
    57: 'CTEXML',
    67: 'CTEXML',
    65: 'NFCXML',
    66: 'NF3EXML',
    62: 'NFCOMXML',
    59: 'SATXML',
    infEventoNFe: 'NFEEVEXML',
    infChave: 'INFCHAVE',
  },

};
