import { ReferencialDialogComponent } from './referencial-dialog/referencial-dialog.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { InicioComponent } from './../../view/components/inicio/inicio.component';
import { ChartInfoComponent } from '../components/chart-info/chart-info.component';
import { PieChartComponent } from '../components/piechart/piechart.component';
import { DeleteContribuinteDialogComponente } from '../components/delete-contribuinte-dialog/delete-contribuinte-dialog.component';
import { BoxComponent } from './box/box.component';
import { StatusboxComponent } from './statusbox/statusbox.component';
import { HelpDialogComponent } from './help-dialog/help-dialog.component';
import { HomeComponent } from './home/home.component';
import { UploadsComponent } from './uploads/uploads.component';
import { UploadBoxComponent } from './upload-box/upload-box.component';
import { DialogModalComponent } from './dialog-modal/dialog-modal.component';
import { ManutencaoDialogComponent } from './manutencao-dialog/manutencao-dialog.component';

import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { PvaComponent } from './pva/pva.component';
import { UploadDragAndDropComponent } from './upload-drag-and-drop/upload-drag-and-drop.component';

import { FieldsModule } from './fields/fields.module';
import { ChartsModule } from 'ng2-charts';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { DndDirective } from '../directive/dnd.directive';

import { EtapaInfoModule } from '../components/etapa-info/etapa-info.module';
import { MatFileUploadModule } from 'mat-file-upload';
import { MaterialModule } from '../material/material.module';
import { MatSelectFilterModule } from 'mat-select-filter';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';

import { ListDialogComponent } from './gerenciar-integracoes-dialogs/list-dialog/list-dialog.component';
import { ConfirmComponent } from './gerenciar-integracoes-dialogs/confirm/confirm.component';
import { ModalGenericaComponent } from './modal-generica/modal-generica.component';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import { MatDialogModule } from '@angular/material/dialog';
import { LoadingComponent } from './loading/loading.component';
import { GridProdutosComponent } from './grid-produtos/grid-produtos.component';
import { SegrecacaodetalhesComponent } from './segregacaodetalhes/segregacaodetalhes.component';
import { AuditoriaDetalhesComponent } from './historico-upload/components/auditoria-detalhes/auditoria-detalhes.component';
import { AuditoriaErrosDetalhesComponent } from './historico-upload/components/auditoria-erros-detalhes/auditoria-erros-detalhes.component';
import { AuditoriaErrosListadosComponent } from './historico-upload/components/auditoria-erros-listados/auditoria-erros-listados.component';
import { HistoricoUploadComponent } from './historico-upload/historico-upload.component';
import { LoadingTableComponent } from './loading-table/loading-table.component';
import { MatTableModule } from '@angular/material/table';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DxPieChartModule } from 'devextreme-angular';
import { IncluirProdutos } from './incluir-produtos/incluir-produtos.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    IncluirProdutos,
    HistoricoUploadComponent,
    AuditoriaDetalhesComponent,
    LoadingTableComponent,
    AuditoriaErrosDetalhesComponent,
    AuditoriaErrosListadosComponent,
    PieChartComponent,
    SegrecacaodetalhesComponent,
    BoxComponent,
    StatusboxComponent,
    ChartInfoComponent,
    HomeComponent,
    InicioComponent,
    UploadsComponent,
    LoadingComponent,
    UploadBoxComponent,
    DialogModalComponent,
    HelpDialogComponent,
    ConfirmDialogComponent,
    ReferencialDialogComponent,
    ManutencaoDialogComponent,
    PvaComponent,
    UploadDragAndDropComponent,
    DeleteContribuinteDialogComponente,
    DndDirective,
    ListDialogComponent,
    ConfirmComponent,
    ModalGenericaComponent,
    GridProdutosComponent,
  ],
  imports: [
    NgxMaskModule.forRoot(),
    CommonModule,
    MaterialModule,
    FontAwesomeModule,
    ChartsModule,
    FieldsModule,
    FormsModule,
    EtapaInfoModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFileUploadModule,
    MatTooltipModule,
    MatSelectFilterModule,
    MatIconModule,
    MatExpansionModule,
    MatDialogModule,
    DxPieChartModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule.forChild([
      {
        path: 'dashboard',
        component: InicioComponent,
      },
    ]),
  ],
  exports: [
    EtapaInfoModule,
    DndDirective,
    PieChartComponent,
    ManutencaoDialogComponent,
    BoxComponent,
    StatusboxComponent,
    ChartInfoComponent,
    HomeComponent,
    InicioComponent,
    UploadsComponent,
    UploadBoxComponent,
    DialogModalComponent,
    PvaComponent,
    HelpDialogComponent,
    LoadingComponent,
    UploadDragAndDropComponent,
    DndDirective,
    GridProdutosComponent,
  ],
})
export class ComponentsModule {}
